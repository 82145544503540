#home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

}

/* Hide the scrollbar in WebKit browsers (Chrome, Safari) */
#home::-webkit-scrollbar {
  width: 0.5em;
}

#home::-webkit-scrollbar-thumb {
  background-color: transparent;
}

#home::-webkit-scrollbar-track {
  background-color: transparent;
}

.banner {
  text-align: center;
  color: white;  /* Add your desired text color */
}

.banner-text {
  max-width: 600px;  /* Adjust the max-width based on your design */
  margin: 0 auto;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, #00000000, #000000); /* Adjust colors as needed */
  }


@font-face {
    font-family: 'PressStart2P';
    src: local('PressStart2P'), url('/src/PressStart2P-vaV7.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  h1.responsive-headline {
    font-family: 'PressStart2P', sans-serif;
  }


.container {
    text-align: center;
  }
  
  .centered-image {
    max-width: 100%;
    height: auto;
    margin: 10px auto; /* Adjust the margin as needed */
    margin-top: -20px 
  }

  .rounded-image {
    border-radius: 50%; /* Use 50% for a circular shape, adjust as needed */
  }

  .copy-box {
    display: flex;
    align-items: center;
    background-color: #f0f0f00e;
    border: 1px solid #ccc;
    margin: 10px auto; /* Adjust margin for top and bottom space, and set auto for horizontal centering */
    height: 40px; /* Adjust the height as needed */
    max-width: 400px; /* Adjust the width as needed */
  }
  
  .copy-box p {
    flex-grow: 1;
    margin: 0;
    color: white;
    font-size: 1.1rem;
    padding-left: 12px;
  }
  
  .copy-box button {
    align-self: center; /* Center the button vertically */
    padding: 5px;
    cursor: pointer;
    border: none;
    background-color: #e0e0e000;
    color: #333;
    vertical-align: middle; /* Adjust the vertical alignment */
    height: 20px;
  }
  
  .copy-box button:disabled {
    cursor: not-allowed;
    background-color: #ccc;
  }

  .responsive-image {
    max-width: 45%; /* Ensure the image doesn't exceed its container width */
    height: auto; /* Maintain the aspect ratio */
  }
  
  .wosmo-item {
    position: relative;
    display: inline-block;
  }

  #wosmoBox {
    display: none;
    background-color: #190a38f3; /* Add your desired background color */
    padding: 20px; /* Add padding for better aesthetics */
    border-radius: 5px; /* Optional: Add border-radius for rounded corners */
    position: absolute; /* Use absolute positioning */
    top: 60%; /* Position below the $WOSMO link */
    z-index: 1; /* Ensure the box appears above other elements */
    left: 50%;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Add transitions for max-height and opacity */
    transform: translateX(-50%); /* Center the box horizontally */

  }

  .wosmo-item:hover #wosmoBox {
    max-height: 200px; /* Adjust the max height as needed */
    opacity: 1;
  }

  .wosmo-item:hover #wosmoBox,
#wosmoBox:hover {
  display: block;
}

#nav a[href="null"]:hover + #wosmoBox {
  display: block;

}

#wosmoBox h1 {
  font-size: 1rem; /* Adjust the font size as needed */
  margin: 0; /* Remove default margin to control spacing */
  color: white;
}

#wosmoBox a:hover h1 {
  color: #cab2fd; /* Change the color to your desired hover color */
}

#nav .nav-link {
  cursor: pointer;
}

#nav .nav-link:hover + #wosmoBox,
#wosmoBox:hover {
  display: block;
}

.nav-link {
  color: white; /* Set the default color */
  text-decoration: none; /* Remove underline from links */
  transition: color 0.3s ease; /* Add a smooth transition effect */
}

.nav-link:hover {
  color: #cab2fd; /* Set your desired hover color */
}
